<template>
    <svg id="Medidas" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 453.54 453.54">
        <defs>
            <linearGradient id="Degradado_sin_nombre_173" data-name="Degradado sin nombre 173" x1="229" y1="149.28" x2="220.67" y2="439.02" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="red"/>
            <stop offset=".23" stop-color="#f80404"/>
            <stop offset=".55" stop-color="#e51012"/>
            <stop offset=".93" stop-color="#c62328"/>
            <stop offset=".99" stop-color="#c1272d"/>
            </linearGradient>
            <filter id="drop-shadow-58" filterUnits="userSpaceOnUse">
            <feOffset dx="11.87" dy="11.87"/>
            <feGaussianBlur result="blur" stdDeviation="8.48"/>
            <feFlood flood-color="#000" flood-opacity=".2"/>
            <feComposite in2="blur" operator="in"/>
            <feComposite in="SourceGraphic"/>
            </filter>
        </defs>
        <rect fill="url(#Degradado_sin_nombre_173)" filter="url(#drop-shadow-58)" x="81.84" y="81.84" width="289.85" height="289.85" rx="144.93" ry="144.93"/>
        <path :fill="color" d="m179.13,290.34c-6.7-.52-11.91-3.59-14.59-10.03-2.74-6.58-1.41-12.66,3.54-17.7,10.54-10.72,21.23-21.3,31.86-31.93q3.91-3.92-.07-7.91c-10.63-10.64-21.32-21.21-31.85-31.94-4.72-4.8-6.1-10.63-3.7-17.03,2.34-6.23,7.05-9.71,13.59-10.46,5.38-.62,9.85,1.5,13.62,5.29,10.71,10.73,21.5,21.38,32.11,32.21,2.33,2.38,3.72,2.61,6.18.09,10.76-11.02,21.69-21.88,32.65-32.71,4.79-4.73,10.55-6.28,16.98-3.99,6.41,2.29,9.99,7.07,10.73,13.77.59,5.38-1.57,9.83-5.35,13.6-10.74,10.7-21.37,21.51-32.22,32.1-2.51,2.45-2.33,3.84.07,6.18,11,10.78,21.88,21.69,32.7,32.67,4.72,4.79,6.21,10.58,3.88,17.01-2.33,6.41-7.14,9.95-13.84,10.65-5.37.56-9.8-1.64-13.57-5.43-10.6-10.66-21.33-21.2-31.83-31.96-2.46-2.52-3.92-2.65-6.45-.05-10.49,10.77-21.24,21.29-31.83,31.97-3.47,3.5-7.53,5.47-12.6,5.63Z"/>
    </svg>
</template>
<script>
export default {
    props:{
        color:{
            type: String,
            default: '#FFFFFF'
        },
        height:{
            type: String,
            default: '20'
        },
        width:{
            type: String,
            default: '20.52'
        }
    }
}
</script>