
import Upload from '@/components/shared/Upload.vue'
import DigitalGuardView from "@/components/views/Index.vue";
import DigitalGuardEvent from "@/components/views/Event.vue";

export default [
  // {path: '/', component: Upload, name: 'root'},
  {path: '/', component: DigitalGuardView, name: 'root'},
  {path: '/events', component: DigitalGuardEvent, name: 'events'},
]
