<template>
    <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 453.54 453.54">
        <defs>
            <linearGradient id="Degradado_sin_nombre_8" data-name="Degradado sin nombre 8" x1="229.09" y1="160.23" x2="219.01" y2="449.91" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#00d41f"/>
            <stop offset=".16" stop-color="#00cd20"/>
            <stop offset=".37" stop-color="#00bc24"/>
            <stop offset=".62" stop-color="#00a02a"/>
            <stop offset=".89" stop-color="#007933"/>
            <stop offset=".99" stop-color="#006837"/>
            </linearGradient>
        </defs>
        <rect fill="url(#Degradado_sin_nombre_8)" x="81.84" y="81.84" width="289.85" height="289.85" rx="144.93" ry="144.93"/>
        <path :fill="color" d="m208.47,285.84c-6.68-.3-12.76-2.26-17.66-6.96-12.35-11.85-24.65-23.75-36.86-35.75-5.46-5.36-5.48-13.59-.38-18.86,5.17-5.33,13.42-5.41,19.06.01,11.1,10.66,22.17,21.37,33.13,32.17,1.99,1.96,3.13,1.94,5.11-.03,23.69-23.43,47.47-46.76,71.2-70.15,2.6-2.56,5.42-4.56,9.17-4.84,5.94-.45,10.94,2.39,13.35,7.56,2.39,5.13,1.46,10.89-2.77,15.14-8.86,8.9-17.82,17.7-26.76,26.52-15.79,15.58-31.63,31.1-47.37,46.72-5.37,5.33-11.7,8.17-19.24,8.46Z"/>
    </svg>
</template>
<script>
export default {
    props:{
        color:{
            type: String,
            default: '#FFFFFF'
        },
        height:{
            type: String,
            default: '20'
        },
        width:{
            type: String,
            default: '20.52'
        }
    }
}
</script>