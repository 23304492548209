<template>
    <article>
        <!-- <loader v-if="loader"></loader>-->
        <div class="access_view" 
            :style="{'background-image': 'url(' + require('../../assets/img/fondo.png') + ')',
            'background-position': 'center center', 
            'background-size': 'cover'}">
            <div class="access_view__header">
                <img :src="require('../../assets/img/logo_fuseaccess.png')" alt="">
            </div>

            <div class="access_view__main_view" v-if="main_view">
                <img :src="require('../../assets/img/imagen_guardas.png')" alt="">
                <h1>SISTEMA MONITOREADO 24/7</h1>
            </div>

            <div class="access_view__info_view" v-else>
                <div class="access_view__info_view--photo_div">
                    <div class="access_view__info_view--photo_div--known" v-if="known">
                        <div class="access_view__info_view--photo_div--known__icon_div">
                            <welcome-icon></welcome-icon>
                        </div>                        
                        <img :src="photo"/>
                    </div>
                    <div class="access_view__info_view--photo_div--unknown" v-if="unknown">
                        <div class="access_view__info_view--photo_div--unknown__icon_div">
                            <warning-icon></warning-icon>
                        </div>                        
                        <img :src="photo"/>
                    </div>
                </div>
                <div class="access_view__info_view--message_div">
                    <img :src="require('../../assets/img/imagen_guardas.png')">
                    <h1 class="access_view__info_view--message_div--greeting" v-if="known">Te damos la <br> <b>Bienvenida</b></h1>
                    <h1 class="access_view__info_view--message_div--warning" v-if="unknown">Usuario no identificado</h1>
                </div>
            </div>
        </div>
    </article>
</template>
<script>
import { months } from 'moment'
import Loader from '@/components/shared/Loader.vue'
import VirtualGuardLogo from '@/components/shared/VirtualGuardLogo.vue'
import WelcomeIcon from '@/components/shared/icons/WelcomeIcon.vue'
import WarningIcon from '@/components/shared/icons/WarningIcon.vue'
export default {
    data(){
        return{
            main_view: true,
            loader: false,
            photo: "",
            known: false,
            unknown: false,
            current_location: "",
            subscription: null,
            time_out: null,
            known_user_name: ""
        }
    },
    created() {
        this.current_location = this.$route.query.id ? this.$route.query.id : null;
        console.log("current_location", this.current_location);
    },
    channels: {
        FaceNotificationsChannel: {
            connected() {
                console.log("Connected to face notifications channel!");
            },
            received(data) {
                let valid=false
                if( data.message.event_type == "UNKNOWN FACE"){
                    valid=true
                    this.unknown = true
                    this.known = false
                    this.main_view = false
                    this.show_warning = true
                    this.show_greeting = false
                }
                if( data.message.event_type == "KNOWN FACE"){
                    valid=true
                    this.known = true
                    this.known_user_name = data.message.user
                    this.unknown = false
                    this.main_view = false
                    this.show_warning = false
                    this.show_greeting = true
                }
                if(valid) {
                    console.log("llega un evento", data);
                    clearTimeout(this.time_out);
                    this.photo = data.message.photo
                    this.time_out = setTimeout(()=>{
                        this.known = false
                        this.unknown = false
                        this.main_view = true
                        this.show_warning = false
                        this.show_greeting = false
                    }, 10000);
                }
            },
            disconnected() {
                console.log("Disconnected to face notifications channel!");
            }
        },
    },
    mounted() {
        this.subscribeToChannel();
    },
    beforeDestroy() {
        this.unsubscribeFromChannel();
    },
    watch:{
    },
    components:{
        'loader': Loader,
        'welcome-icon': WelcomeIcon,
        'warning-icon': WarningIcon
    },
    computed:{
    },
    methods:{
        subscribeToChannel() {
            if (this.current_location && !this.subscription) {
                // Suscribirse al canal solo si hay un ID de empresa y no se ha suscrito previamente.
                this.subscription = this.$cable.subscribe({
                    channel: "FaceNotificationsChannel",
                    room: `room_face_notifications_${this.current_location}`,
                    location_id: this.current_location,
                });
            }
        },
        unsubscribeFromChannel() {
            if (this.subscription) {
                // Cancelar la suscripción al canal antes de que se destruya el componente.
                this.subscription.unsubscribe();
                this.subscription = null;
            }
        }
    }
}
</script>