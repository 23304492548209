<template>
	<article>
		<!-- <loader v-if="loader"></loader>-->
		<div
			class="access_view"
			:style="{
				'background-image':
					'url(' + require('../../assets/img/fondo.png') + ')',
				'background-position': 'center center',
				'background-size': 'cover',
			}"
		>
			<div class="access_view__header">
				<img
					:src="require('../../assets/img/logo_fuseaccess.png')"
					alt=""
				/>
			</div>

			<!-- <div class="access_view__event" v-for="msg in event_queue">
				<p>{{ msg }}</p>
			</div> -->
			<section class="events__container">
				<section class="events__row">
					<p>Dispositivos</p>
					<div
						@click="suspend_locations_events()"
						class="events__row--button"
						:class="[location_active == true ? 'warning' : '']">
						{{location_active == true ? 'Desactivar' : 'Activar'}}
					</div>
				</section>
				<section
					class="events__by_camera">
					<div
						class="events__card"
						v-for="(msg, index) in event_queue"
						@click="checkEvent(index)"
						v-if="!msg.suspended_event">
						<div
							class="events__card--head"
							:class="[msg.event_code == '131588' && msg.event_code != 'FUSE-ALERT-SUSPEND' ? 'alert' : 'warning', msg.event_checked || msg.event_code == 'FUSE-ALERT-ACTIVATE' ? 'checked' : '', msg.event_code == 'FUSE-ALERT-SUSPEND' ? 'suspended' : '']">
							<p>Se ha detectado <br><b>{{msg.event_type}}</b></p>
						</div>
						<div class="events__card--content">
							<p>En: <b>{{msg.location_name}}</b></p>
							<p>Fecha: <b>{{msg.event_time | moment("DD MMMM YYYY")}}</b></p>
							<p>Hora: <b>{{msg.event_time | moment("h:mm:ss a")}}</b></p>
							<p>Dispositivo: <b>{{ msg.device_name }}</b></p>
						</div>
					</div>
					<div
						class="events__card no_content"
						v-if="event_queue.length <= 0">
						<p>Aun no se han reportado eventos de dispositivos</p>
					</div>
				</section>
				<section class="events__row">
					<p>Comunidad</p>
				</section>
				<section
					class="events__by_community">
					<div
						class="events__card"
						v-for="(msg, index) in messages"
						@click="checkEvent(index)">
						<div
							class="events__card--head"
							:class="[msg.event_checked ? 'checked' : '']">
							<p>Se ha generado una alerta desde <br><b>Comunidad</b></p>
						</div>
						<div class="events__card--content">
							<!-- <p>En: <b>{{msg.location_name}}</b></p> -->
							<p>Fecha: <b>{{msg.event_time | moment("DD MMMM YYYY")}}</b></p>
							<p>Hora: <b>{{msg.event_time | moment("h:mm:ss a")}}</b></p>
						</div>
						<div
							class="events__card--button"
							@click="show_info_alert(msg)"
							:class="[msg.event_checked ? 'checked' : '']">
							Ver Info
						</div>
					</div>
					<div
						class="events__card no_content"
						v-if="messages.length <= 0">
						<p>Aun no se han reportado eventos de comunidad</p>
					</div>
				</section>
			</section>
		</div>
		<section
		 	class="events__info_modal"
			v-if="show_selected_info">
			<section class="events__info_modal--content">
				<!-- <div class="" @click="show_selected_info = false">
					<close-icon />
				</div> -->
				<p class="events__info_modal--title">Alerta de Comunidad</p>
				<p class="events__info_modal--text">{{selected_info.message}}</p>
				<div class="events__info_modal--image" v-if="selected_info.file_url != '' && is_image">
					<img :src="selected_info.file_url" alt="" >
					<!-- <iframe width="560" height="315" :src="selected_info.file_url" frameborder="0" allowfullscreen></iframe> -->
				</div>
				<div class="events__info_modal--video" v-else-if="selected_info.file_url != ''">
					<iframe :srcdoc='doc_video' width="640" height="360" frameborder="0"></iframe>
				</div>
				<div
					class="events__card--button"
					@click="show_selected_info = false">
					Cerrar
				</div>
			</section>
		</section>
	</article>
</template>
<script>
	import { months } from "moment";
	import Loader from "@/components/shared/Loader.vue";
	import VirtualGuardLogo from "@/components/shared/VirtualGuardLogo.vue";
	import WelcomeIcon from "@/components/shared/icons/WelcomeIcon.vue";
	import WarningIcon from "@/components/shared/icons/WarningIcon.vue";
	import CloseIcon from "@/components/shared/icons/CloseIcon.vue";
	export default {
		data() {
			return {
				messages:[],
				loader: false,
				current_location: "",
				subscription: null,
				time_out: null,
				event_queue: [],
				show_selected_info: false,
				selected_info:{
					message:'',
					file_url:''
				},
				doc_video:'',
				is_image: true,
				location_active: true
			};
		},
		created() {
			this.current_location = this.$route.query.id
				? this.$route.query.id
				: null;
			console.log("current_location", this.current_location);
		},
		channels: {
			FaceNotificationsChannel: {
				connected() {
					console.log("Connected to face notifications channel!");
				},
				received(data) {
					if (
						["131585", "131588", "131331", "FUSE-ALERT-SUSPEND", "FUSE-ALERT-ACTIVATE"].includes(data.message.event_code)
					) {
						console.log("llega un evento", data);
						// this.event_queue.unshift(data.message.text_message);
						// if (this.event_queue.length > 5) {
						// 	this.event_queue.pop();
						// }
						if(data.message.suspended_event != true){
							this.event_queue.unshift(data.message);
							if (this.event_queue.length > 5) {
								this.event_queue.pop();
							}
						}
					} else if(
						["FUSE-ALERT-COM"].includes(data.message.event_code)
					){
						console.log("llega un evento de comunidad", data);
						this.messages.unshift(data.message);
						if (this.messages.length > 5) {
							this.messages.pop();
						}
					}
				},
				disconnected() {
					console.log("Disconnected to face notifications channel!");
				},
			},
		},
		mounted() {
			this.subscribeToChannel();
			this.check_suspend_location()
		},
		beforeDestroy() {
			this.unsubscribeFromChannel();
		},
		watch: {},
		components: {
			loader: Loader,
			"welcome-icon": WelcomeIcon,
			"warning-icon": WarningIcon,
			"close-icon": CloseIcon,
		},
		computed: {},
		methods: {
			subscribeToChannel() {
				if (this.current_location && !this.subscription) {
					// Suscribirse al canal solo si hay un ID de empresa y no se ha suscrito previamente.
					this.subscription = this.$cable.subscribe({
						channel: "FaceNotificationsChannel",
						room: `room_face_notifications_${this.current_location}`,
						location_id: this.current_location,
					});
				}
			},
			unsubscribeFromChannel() {
				if (this.subscription) {
					// Cancelar la suscripción al canal antes de que se destruya el componente.
					this.subscription.unsubscribe();
					this.subscription = null;
				}
			},
			checkEvent(index){
				if(!this.messages[index].event_code == "FUSE-ALERT-SUSPEND" &&
					 !this.messages[index].event_code == "FUSE-ALERT-ACTIVATE"){
					this.messages[index].event_checked = true
				}
			},
			show_info_alert(obj){
				this.selected_info.message = obj.event_message
				this.selected_info.file_url = obj.event_image

				if (this.selected_info.file_url.includes(".jpg")||
						this.selected_info.file_url.includes(".png")||
					 	this.selected_info.file_url.includes(".jpeg")) {
				  this.is_image = true
				} else {
					this.is_image = false
					this.doc_video = '<!DOCTYPE html>\
						<html lang="es">\
						<head>\
							<meta charset="UTF-8">\
							<meta name="viewport" content="width=device-width, initial-scale=1.0">\
							<title>Embeber Video</title>\
						</head>\
						<body>\
						<video controlsList="nodownload" controls width="600" height="300px">\
							<source src="'+this.selected_info.file_url+'" type="video/mp4">\
							Tu navegador no soporta la etiqueta de video.\
						</video>\
						</body>\
						</html>'
				}
				this.show_selected_info = true
			},
			suspend_locations_events(){
				try {
          this.$http.post('app/locations/suspend_locations_events',{
						data:this.encrypt({
							location_id: this.current_location,
              action: this.location_active == true ? "suspend" : "activate"
            }).toString()
          })
          .then(function(response){
            this.location_active = !this.location_active
          }, function(response){
            alert("Hubo un problema al desactivar el servicio, intenta nuevamente")
          })
        } catch (e) {
          alert(e.message)
        }
			},
			check_suspend_location(){
				try {
          this.$http.get('app/locations/get_locations_for_suspend?location_id='+this.current_location,{
          })
          .then(function(response){
						console.log(response);
            this.location_active = !response.body.suspended
          }, function(response){
            alert("Hubo un problema al verificar el servicio, intenta nuevamente")
          })
        } catch (e) {
          alert(e.message)
        }
			}



		},
	};
</script>
